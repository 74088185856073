.botonMenu{
    position : fixed;
    top : 5%;
    left : 3%;  
    width : 4%;
    height: auto;
    margin-bottom : 5%;
    z-index : 2 ;
    background-color : transparent;
    border : none ;

}

.imagenBotonMenu{
    width : 100%;
    object-fit : cover  ;
    transition : transform 0.6s ease ;
    
}

.botonMenu:hover .imagenBotonMenu{
    transform : rotate(180deg);
}

.menuLateral{
    width : 18%;
    height: 100vh;
    background-color :#F2542D;
    position :fixed ; 
    left : 0 ; 
    top : 0 ; 
    z-index : 1 ;

    
}
.botonera-MenuLateral{
    margin-top : 50%;
    margin-left : 10%;
}

.botonBarraLateral{
    font-size : 1.5vw;
    font-weight: bold;
    background-color: transparent;
    border : none;  
    margin-bottom : 4%;
    color : #03173a;

}
.botonBarraLateral:hover{
    color : white;
}



.menuLateral {
    animation: 1s anim-MenuLeft ease-out ;
  }
  @keyframes anim-MenuLeft {
    0% {
      opacity: 0;
      transform: translateX(-60%);
    }
    20% {
      opacity: 0;
    }
    50% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }


  @media (max-width: 500px) {

    .botonMenu{
        top : 3%;
        left : 4%;
        width : 10%;
    }
    
    .menuLateral{
        width : 40%;
    }
    .botonBarraLateral{
        font-size: 3vw;
    }




  }