
.divTextoReglamento{
    margin-left : 40%;
    margin-top : 5%;
    font-size : 3vw;
}

.contenedorReglamento  {
    width : 60%;
    margin-left : 22%;
    margin-top : 4%;
 
}

@media(max-width:500px){
    .divTextoReglamento{
        margin-top : 10%;
        margin-left : 35%;
        font-size : 5vw;
    }
    .contenedorReglamento{
        width :70%;
        margin-top : 10%;
        margin-left: 10%;
    }
    
}


